<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.contact"
        class="active-chat"
        style="height: auto !important;"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>
            </div>
          </header>
        </div>
      </div>
      <div
        v-if="!activeChat.contact"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          Start Conversation
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.contact.avatar"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant(activeChat.contact.status)"
                @click.native="shallShowActiveChatContactSidebar=true"
              />
              <h6 class="mb-0">
                {{ activeChat.contact.fullName }}
              </h6>
            </div>

            <!-- Contact Actions -->
            <div
              v-if="showCheckBtns()"
              class="d-flex align-items-center"
            >
              <b-button
                size="sm"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="sendCheckIn"
              >
                Check In
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="sendCheckOut"
              >
                Check Out
              </b-button>

            </div>
          </header>
        </div>

        <!-- User Chat Area -->

        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <b-overlay
            :show="show"
            :no-wrap="show"
          >
            <chat-log
              :chat-data="activeChat"
              :profile-user-avatar="profileUserDataMinimal.avatar"
            />
            <template #overlay>
              <div class="text-center text-primary">
                <feather-icon
                  icon="FileIcon"
                  size="56"
                />
                <p
                  id="cancel-label"
                  class="font-large-1 m-2"
                >
                  {{ selectedFile.name }}
                </p>
                <b-button
                  ref="cancel"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-danger"
                  class="mr-1"
                  size="sm"
                  aria-describedby="cancel-label"
                  @click="cancelSendFile"
                >
                  Cancel
                </b-button>
                <b-button
                  ref="cancel"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  size="sm"
                  aria-describedby="cancel-label"
                  @click="sendFileMessage"
                >
                  Send
                </b-button>
              </div>
            </template>
          </b-overlay>
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <feather-icon
            icon="PaperclipIcon"
            class="cursor-pointer mr-1"
            size="21"
            @click="handleSendFile"
          />
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Enter your message"
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
          >
            Send
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat.contact || {}"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="activeChat.contact ? activeChat.contact.uxid : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
    <audio
      id="notificationSound"
      src=""
      preload="auto"
    />
  </div>
</template>

<script>
import {
  nextTick,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import {
  BAvatar,
  BButton,
  BForm,
  BFormInput,
  BInputGroup,
  BOverlay,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
// import { formatDate } from '@core/utils/filter'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ChatSocketService from '@core/services/chat/chatSocketService'
import { $themeBreakpoints } from '@themeConfig'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import notificationSound from '@/assets/audio/notification.mp3'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import ChatLog from './ChatLog.vue'
import chatStoreModule from './chatStoreModule'
import useChat from './useChat'

export default {
  components: {

    // BSV
    BAvatar,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BOverlay,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  directives: {
    Ripple,
  },
  methods: {
    showCheckBtns() {
      const user = this.$store.getters['user-auth/getUser']
      return user.role !== 'CLIENT'
    },
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    const toast = useToast()

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })

    const {
      resolveAvatarBadgeVariant, sendNewMessage, sendNewCheckin, sendNewCheckout,
    } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([])
    const contacts = ref([])

    /* eslint-disable */
    const formattChats = chats => {
      const listChats = []
      chats.forEach((chat, i) => {
        const date = new Date((chat.lastMessage ? chat.lastMessage.creationDate : 0) * 1000)
        const localDateString = date.toLocaleString()
        const newChat = {
          about: '',
          avatar: '',
          chat: {
            id: chat.id,
            lastMessage: {
              message: chat.lastMessage ? (chat.lastMessage.chatType === 'FILE' ? decodeURIComponent(chat.lastMessage.message.split('/').pop()) : chat.lastMessage.message) : '',
              senderId: chat.lastMessage && chat.lastMessage.owner ? chat.lastMessage.owner.id : chat.member.id,
              time: chat.lastMessage ? localDateString : '',
              time2: chat.lastMessage ? date : '',
            },
            unseenMsgs: 0,
          },
          fullName: chat.member.business !== '' ? chat.member.business : `${chat.member.lastname} ${chat.member.firstname}`,
          // chatName: chat.member.business !== '' ? chat.member.business : `${chat.member.lastname} ${chat.member.firstname}`,
          id: chat.member.id,
          uxid: `${chat.member.id}${i}`,
          role: '',
          status: 'offline',
        }
        listChats.push(newChat)
      })
      return listChats.sort((a, b) => b.chat.lastMessage.time2 - a.chat.lastMessage.time2)
    }
        /* eslint-enable */

    const fetchChatAndContacts = () => {
      store.dispatch('app-chat/fetchChatsAndContacts')
        .then(response => {
          chatsContacts.value = formattChats(response.data)
        })
    }

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')

    const formattMessages = (listMessages, id) => {
      const newList = []
      const user = store.getters['user-auth/getUser']
      listMessages.forEach(message => {
        const date = new Date(message.creationDate * 1000)
        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }
        const localDateStrin2 = date.toLocaleDateString('en-US', options)
        const localDateString = date.toLocaleString()
        // eslint-disable-next-line no-nested-ternary
        const idSender = message.chatType !== 'CHAT' && message.chatType !== 'FILE' ? (user.role === 'CLIENT' ? id : user.id) : (message.owner ? message.owner.id : user.id)
        const newMessage = {
          message: message.message,
          time: localDateString,
          time2: localDateStrin2,
          senderId: idSender,
          type: message.chatType,
        }
        newList.push(newMessage)
      })
      return newList
    }

    const openChatOfContact = chatId => {
      // Reset send message input value
      chatInputMessage.value = ''
      const user = store.getters['user-auth/getUser']
      store.dispatch('app-chat/getChat', { chatId })
        .then(response => {
          // Set unseenMsgs to 0
          const contact = chatsContacts.value.find(c => c.chat.id === chatId)
          if (contact) contact.chat.unseenMsgs = 0

          const newActiveChat = {
            chat: {
              chat: formattMessages(response.data, contact.id),
              id: chatId,
              uxid: contact.uxid,
              unseenMsgs: 0,
              userId: user.id,
            },
            contact,
          }
          activeChat.value = newActiveChat
          // Scroll to bottom
          nextTick(() => { scrollToBottomInChatLog() })
        })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }

    fetchChatAndContacts()

    let audioElement

    const playSound = () => {
      if (!audioElement) {
        audioElement = new Audio(notificationSound) // Create audio element on demand
        audioElement.preload = 'auto' // Preload for faster playback
      }
      audioElement.play()
    }

    const handleChatUpdate = message => {
      fetchChatAndContacts()
      openChatOfContact(message.channel)
      playSound()
      // THE FUNCTION I WANT TO CALL FROM SETUP FILE
    }

    ChatSocketService.connectToChatUpdates(handleChatUpdate)

    const sendCheckIn = () => {
      const user = store.getters['user-auth/getUser']
      const chatMessage = {
        owner: user.id, // id del usuario loggeado
        message: 'The agent has entered in the chat',
        channel: activeChat.value.chat.id, // id del chat compartido
        chatType: 'JOIN',
      }
      const contactId = activeChat.value.contact.id
      sendNewCheckin(contactId, chatMessage)
      setTimeout(() => {
        openChatOfContact(activeChat.value.chat.id)
        fetchChatAndContacts()
      }, 500)
    }

    const sendCheckOut = () => {
      const user = store.getters['user-auth/getUser']
      const chatMessage = {
        owner: user.id, // id del usuario loggeado
        message: 'The agent has left the chat',
        channel: activeChat.value.chat.id, // id del chat compartido
        chatType: 'LEAVE',
      }
      const contactId = activeChat.value.contact.id
      sendNewCheckout(contactId, chatMessage)
      setTimeout(() => {
        openChatOfContact(activeChat.value.chat.id)
        fetchChatAndContacts()
      }, 500)
    }

    const selectedFile = ref(null)
    const errorMessage = ref(null)
    const show = ref(false)

    const sendFileMessage = () => {
      const formData = new FormData()
      formData.append('file', selectedFile.value)
      const contactId = activeChat.value.chat.id
      store.dispatch('app-chat/sendFileMessage', { contactId, formData })
        .then(() => {
          openChatOfContact(activeChat.value.chat.id)
          fetchChatAndContacts()
          selectedFile.value = null
          show.value = false
        })
    }

    /* eslint-disable consistent-return */
    const sendMessage = () => {
      // if (selectedFile.value) return sendFileMessage()
      if (!chatInputMessage.value) return
      const user = store.getters['user-auth/getUser']
      const chatMessage = {
        owner: user.id, // id del usuario loggeado
        message: chatInputMessage.value,
        channel: activeChat.value.chat.id, // id del chat compartido
        chatType: 'CHAT',
      }
      const contactId = activeChat.value.contact.id
      sendNewMessage(contactId, chatMessage)
      setTimeout(() => {
        openChatOfContact(activeChat.value.chat.id)
        fetchChatAndContacts()
      }, 500)
    }
    /* eslint-enable consistent-return */

    const validateFile = file => {
      const fileSize = file.size / 1024 / 1024 // Convert size to MB
      const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      if (!allowedTypes.includes(file.type)) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Unsupported File Format',
            text: 'Only JPG, PNG, DOC, and XLSX files are allowed. Please select a file in one of these formats.',
            icon: 'HardDriveIcon',
            variant: 'error',
          },
        }, { timeout: 3000 })
        return false
      }

      if (fileSize > 15) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'File Size Limit Exceeded',
            text: 'The file you selected is larger than 15MB. Please choose a smaller file and try again.',
            icon: 'HardDriveIcon',
            variant: 'error',
          },
        }, { timeout: 3000 })
        return false
      }

      return true
    }

    const handleFileChange = event => {
      const file = event.target.files[0]
      const isValid = validateFile(file)
      if (isValid) {
        selectedFile.value = file
        errorMessage.value = null
        show.value = true
      } else {
        errorMessage.value = 'Invalid file type or size (Max 15MB)'
        selectedFile.value = null
      }
    }

    const cancelSendFile = () => {
      show.value = false
      selectedFile.value = null
      setTimeout(() => {
        scrollToBottomInChatLog()
      }, 100)
    }

    const handleSendFile = () => {
      // Create a hidden file input element
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = '//' // Accept all file types (adjust as needed)
      input.style.display = 'none'

      // Add event listener for file change and trigger click
      input.addEventListener('change', e => {
        handleFileChange(e)
      })
      input.click()

      // Remove the element after use
      // input.removeEventListener('change', handleFileChange)
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Filters
      // formatDate,

      // useChat
      sendCheckIn,
      sendCheckOut,
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,
      handleSendFile,
      show,
      sendFileMessage,
      selectedFile,
      cancelSendFile,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
